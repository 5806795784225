<template>
  <ErrorFrame :error-type="errorType"><slot /></ErrorFrame>
</template>
<script setup lang="ts">
import type { ErrorIdent } from '~/@types/errorIdents';

defineProps({
  errorType: {
    type: String as PropType<ErrorIdent>,
    required: false,
    default: '',
  },
});
const ErrorFrame = defineAsyncComponent(() => {
  if (useSiteIdent() === SiteIdent.default) {
    return import('./vdv/errorFrame.vue');
  } else {
    return import('./minilu/errorFrame.vue');
  }
});
</script>
